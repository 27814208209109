<!--
This component is a Vue.js single-file component that displays a line chart using D3.js library. 
The chart displays the player count for each map over time, 
based on the data provided in the "snapshots" and "maps" props. 
The chart also uses different colors for each map to make it easier to distinguish between them.
-->

<template>
    <div>
        <div v-if="!chartData">
            <div class="d-flex justify-content-center align-items-center" style="height:300px">
                <b-spinner label="Loading..." variant="primary" />
            </div>
        </div>
        <D3LineChart style="height:300px" v-if="chartData" :datum="chartData" :config="chartOptions" />
    </div>
</template>
  
<script>
import { getLog } from '@/services/log'
const log = getLog('snapshots-charts');
import { D3LineChart } from 'vue-d3-charts'

export default {
    components: {
        D3LineChart,
    },
    props: {
        snapshots: {
            type: Array,
            required: true
        },
        maps: {
            type: Array,
            required: true
        },
        countKey: { 
            type: String,
            default: 'playerCount'
        },
    },
    data() {
        return {
            chartData: null,
            chartOptions: null,
            mapColors: {},
        }
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        this.chartData = null;
    },
    watch: {
        snapshots() {
            this.chartData = null;
            this.$nextTick(() => {
                this.prepareChartData();
            })
        },
    },
    methods: {
        async init() {
            this.chartData = null;
            this.$nextTick(() => {
                this.prepareChartData();
            })
        },
        // replace index from islandCode to map name and return new object
        replaceIndex(data) {
            const mapNames = {};
            this.maps.forEach(map => {
                mapNames[map.islandCode] = map.name;
            })
            // clean up map names, remove anything that is not a letter, a number, or space, ascii only
            Object.keys(mapNames).forEach(key => {
                mapNames[key] = mapNames[key].replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_');
            })
            const newData = {};
            Object.keys(data).forEach(key => {
                if (key === 'date') {
                    newData[key] = data[key];
                } else {
                    newData[mapNames[key]] = data[key];
                }
            })
            return newData;
        },
        prepareChartData() {
            log.log('prepareChartData');//, this.snapshots, this.maps);
            const chartData = []
            let values = {};
            this.snapshots.forEach(obj => {
                const data = {};
                // log.log('data', data);
                obj.maps.forEach(map => {
                    if (Object.keys(data).length < 20) {
                        data[map.islandCode] = map[this.countKey] || 0;
                        values[map.islandCode] = true;
                    }
                })
                data.date = obj.timestamp.toDate().toISOString().slice(0, 19);
                // log.log('data', data);
                chartData.push(this.replaceIndex(data));
            })
            values = this.replaceIndex(values);
            log.log('chartData', chartData);
            values = Object.keys(values);
            // normalize values in chart data
            chartData.forEach(data => {
                values.forEach(value => {
                    if (!data[value]) {
                        data[value] = 0;
                    }
                })
            })
            // Add more colors
            const colors = [
                '#f44336',
                '#e81e63',
                '#9c27b0',
                '#673ab7',
                '#3f51b5',
                '#2196f3',
                '#03a9f4',
                '#00bcd4',
                '#009688',
                '#4caf50',
                '#8bc34a',
                '#cddc39',
                '#ffeb3b',
                '#ffc107',
                '#ff9800',
                '#ff5722',
                '#795548',
                '#9e9e9e',
                '#607d8b',
                '#DeDeDe',];

            // Create an object to store the color for each map
            const mapColors = {};
            // Loop through the values array and assign a color to each map
            values.forEach((map, index) => {
                // If we run out of colors, start over from the beginning
                const colorIndex = index % colors.length;
                mapColors[map] = colors[colorIndex];
            });
            // log.log('mapColors', mapColors);
            // Set the mapColors data property to the mapColors object we just created
            this.mapColors = mapColors;
            this.chartData = chartData;
            this.chartOptions = {
                values,
                date: {
                    key: 'date',
                    inputFormat: '%Y-%m-%dT%H:%M:%S',
                    outputFormat: '%Y-%m-%dT%H:%M:%S',
                },
                axis: {
                    yTicks: 3,
                },
                color: {
                    scheme: colors,
                },
            };
            // log.log('this.chartOptions', this.chartOptions);
        },
    }
}
</script>
  
<style scoped></style>
    